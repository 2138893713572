
import GroupsSelector from "@/components/GroupsSelector.vue";
import SelectedGroups from "@/components/SelectedGroups.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {
    Modal,
    GroupsSelector,
    SelectedGroups
  }
})
export default class GroupsSelectorModal extends Vue {

  selectedGroups = [];

  save() {
    this.$emit("selected");
    this.onClose();
  }

  onClose() {
    this.$emit("close");
  }
}
