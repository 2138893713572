
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import LabelValueListItem from "@/components/LabelValueListItem.vue";
import Modal from "@/components/Modal.vue";
import PrivacyModalContent from "@/components/PrivacyModalContent.vue";
import AreaSelectorModal from "@/components/AreaSelectorModal.vue";
import GroupsSelectorModal from "@/components/GroupsSelectorModal.vue";
import ProfileAvatarDialog from "@/components/ProfileAvatarDialog.vue";
import moment, { parseDate } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { isCompanyReferent } from "@/utils/status";
import {
  GENDER_FEMALE_CODE,
  GENDER_MAN_CODE,
  GENDER_OTHER_CODE,
  getGenderText,
  getReferentName,
} from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Card,
    Btn,
    LabelValueListItem,
    ProfileAvatarDialog,
    Modal,
    PrivacyModalContent,
    AreaSelectorModal,
    GroupsSelectorModal
  },
})
export default class PersonalData extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.selectedGroups) selectedGroups: Api.CodeDecodeChild[];
  @Action getUser;
  @Mutation showError;
  @Mutation showSuccess;
  @Mutation setSelectedArea;
  @Mutation setSelectedGroups;
  @Getter getArea;
  @Getter getSubGroup;
  showAvatarDialog = false;

  contentReady = false;
  admin1Role = RoleCodes.ADMIN1;
  admin2Role = RoleCodes.ADMIN2;
  userRole = RoleCodes.USER;

  qualification = "";
  privacyModal = false;
  showAreaSelectorModal = false;
  showGroupsSelectorModal = false;

  genderData = [
    { label: "Uomo", value: GENDER_MAN_CODE },
    { label: "Donna", value: GENDER_FEMALE_CODE },
    { label: "Altro", value: GENDER_OTHER_CODE },
  ];

  async mounted() {
    await this.getUser();
    this.contentReady = true;
  }

  showItem(roleCodes: RoleCodes[]) {
    return checkUserRole(this.user, roleCodes);
  }

  getNameSurname() {
    return this.user.name + " " + this.user.surname;
  }

  getUserRole() {
    return this.user.role?.des;
  }

  getReferentName() {
    if (this.hasReferent()) {
      return getReferentName(this.user);
    } else return "Referente CNA ancora non associato";
  }

  hasReferent() {
    return getReferentName(this.user) != "";
  }

  getAssociated() {
    if (this.user.associated) {
      return "Utente associato";
    } else {
      return "Utente non associato";
    }
  }

  getCompanyName() {
    if (!_.isNil(this.user.company)) {
      return this.user.company.ragsoc;
    }
    return "";
  }

  isReferent() {
    return isCompanyReferent(this.user.status.code);
  }

  isAdminLevel1() {
     return checkUserRole(this.user, [RoleCodes.ADMIN1]);
  }

  isAdminLevel2() {
     return checkUserRole(this.user, [RoleCodes.ADMIN2]);
  }

  async handleGenderChange(gender) {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $gender: String, $donImp: Int) {
          updateUser(guid: $guid, gender: $gender, cna_imp_don: $donImp)
        }
      `,
      variables: {
        guid: this.user.guid,
        gender: gender,
        donImp: gender == GENDER_FEMALE_CODE ? 1 : 0,
      },
    });
    if (res.data.updateUser) {
      this.showSuccess("Profilo aggiornato correttamente");
    }
    await this.getUser();
  }

  hasBirthDateSecondLabel() {
    const birthDate = parseDate(this.user.birth_date);
    return birthDate.diff(moment(), "years") * -1 <= 40;
  }

  getAdminArea() {
    if (this.hasAdminArea()) {
      return this.getArea([this.user.macroarea_guid])[0].des;
    } else {
      return "Non ti è stata associata ancora nessun area";
    }
  }

  hasAdminArea() {
    const areas = this.getArea([this.user.macroarea_guid]);
    return !_.isEmpty(areas);
  }

  getAdminSubGroups() {
    if (this.hasAdminSubGroups()) {
      let text = ""
      _.forEach( this.getSubGroup(this.user.macroarea_sottogruppo), (group, index) => {
        if(parseInt(index) == 0){
          text = group.des
        }
        if(parseInt(index) > 0){
          text = text + " - " +group.des
        }
      });
      return text;
    } else {
      return "Non ti è stato associato ancora nessun sottogruppo";
    }
  }

  hasAdminSubGroups() {
    const subGroups = this.getSubGroup(this.user.macroarea_sottogruppo);
    return !_.isEmpty(subGroups);
  }

  getAdminServices() {
    if (this.hasAdminServices()) {
      let text = ""
      _.forEach( this.user.services, (service, index) => {
        if(index == 0){
          text = service.des
        }
        if(index > 0){
          text = text + " - " + service.des
        }
      });
      return text;
    } else {
      return "Non ti è stato associato ancora nessun servizio";
    }
  }

  hasAdminServices() {
    return !_.isEmpty(this.user.services);
  }

  hasHeadquarter() {
    return !_.isNil(this.user.headquarter_guid)
  }

  getHeadquarter() {
    if (this.hasHeadquarter()) {
      return this.user.headquarter.city;
    } else {
      return "Non ti è stata associata nessuna sede";
    }
  }

  getGenderValue() {
    if (!_.isNil(this.user)) {
      return getGenderText(this.user.gender);
    }
    return "";
  }

  async openEditAreaModal(){
    const areas = this.getArea([this.user.macroarea_guid])
    if(!_.isEmpty(areas)){
      await this.setSelectedArea(areas[0])
    }
    this.showAreaSelectorModal = true
  }

  async updateArea(area) {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $area: String) {
          updateUser(guid: $guid, macroarea_guid: $area)
        }
      `,
      variables: {
        guid: this.user.guid,
        area: area.guid,
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.showSuccess("Area aggiornata correttamente");
    } else {
      this.showError("Errore durante aggiornamento Area");
    }
  }

  async openEditGroupsModal(){
    const areas = this.getArea([this.user.macroarea_guid])
    if(!_.isEmpty(areas)){
      await this.setSelectedArea(areas[0])
      await this.setSelectedGroups(this.getSubGroup(this.user.macroarea_sottogruppo))
    }
    this.showGroupsSelectorModal = true
  }

  async updateGroups() {
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation put($guid: ID!, $groups: [String]) {
          updateUser(guid: $guid, macroarea_sottogruppo: $groups)
        }
      `,
      variables: {
        guid: this.user.guid,
        groups: _.map(this.selectedGroups, (group) => {return group.guid}),
      },
    });
    if (res.data.updateUser) {
      await this.getUser();
      this.showSuccess("Sottogruppi aggiornati correttamente");
    } else {
      this.showError("Errore durante aggiornamento Sottogruppi");
    }
  }
}
