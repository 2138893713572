
import AreaSelector from "@/components/AreaSelector.vue";
import Modal from "@/components/Modal.vue";
import Vue from "vue";
import { State } from "vuex-class";
import { State as StateStore } from "../store";
import Component from "vue-class-component";
import * as Api from "@/types/graphql";

@Component({
  components: {
    Modal,
    AreaSelector,
  },
})
export default class AreaSelectorModal extends Vue {
  @State((state: StateStore) => state.data.selectedArea)
  selectedAreaState: Api.CodeDecode;

  selectedArea = null;

  save() {
    this.$emit("selected", this.selectedArea);
    this.onClose();
  }

  onClose() {
    this.$emit("close");
  }
}
