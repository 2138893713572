
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import { RawLocation } from "vue-router";
import Avatar from "@/components/Avatar.vue";
import * as Api from "@/types/graphql";
import gql from "graphql-tag";

@Component({
  components: { Avatar },
})
export default class LabelValueListItem extends Vue {
  @Prop()
  isWhite: boolean;
  @Prop()
  labelKey: string;
  @Prop()
  value: string;
  @Prop()
  valueClass: string;
  @Prop()
  topBorder: boolean;
  @Prop()
  topBorderNoMargin: boolean;
  @Prop()
  bottomBorder: boolean;
  @Prop()
  bottomBorderNoMargin: boolean;
  @Prop()
  editable: boolean;
  @Prop()
  hasAvatar: boolean;
  @Prop()
  showEmpty: boolean;
  @Prop()
  emptyText: string;
  @Prop()
  avatarUser: Api.User;
  @Prop()
  descriptionKey: string;
  @Prop()
  multiline: boolean;
  @Prop()
  custom: boolean;
  @Prop()
  paymentType: string;
  @Prop({ default: false })
  isTypedList: boolean;
  @Prop()
  itemType: string;
  @Prop({ default: false })
  showValueAsTitleAndContent: boolean;
  @Prop()
  valueTitle: string;
  @Prop()
  valueContent: string;
  @Prop({
    default: () => {
      return [];
    },
  })
  itemsLoaded: any[];
  @Prop({ default: false })
  hasRadioButtons: boolean;
  @Prop()
  radioButtonsData: { label: string; value: string }[];
  @Prop({ default: false })
  isWhiteSpace: boolean;
  @Prop({ default: false })
  hasSecondLabel: boolean;
  @Prop()
  secondLabelIcon: string;
  @Prop()
  secondLabelText: string;
  @Prop({ default: false })
  handleClick: boolean;

  items = [];
  radioModel = null;

  async mounted() {
    if (this.isTypedList) {
      if (this.itemType == "qualifications") await this.getUserQualifications();
    }
    if (this.hasRadioButtons) {
      this.radioModel = this.value;
    }
  }

  async getUserQualifications() {
    if (_.isEmpty(this.itemsLoaded)) {
      const userRes = await this.$apollo.query({
        query: gql`
          query {
            UserByToken {
              qualifications {
                guid
                des
                type_guid
                qualificationtype {
                  guid
                  des
                  custom
                }
              }
            }
          }
        `,
        fetchPolicy: "no-cache",
      });
      _.mapKeys(
        _.groupBy(
          userRes.data.UserByToken.qualifications as Api.Qualification[],
          "qualificationtype.des"
        ),
        (value, key) => {
          this.items.push({ header: key, isHeader: true });
          _.forEach(value, (item) => this.items.push(item));
        }
      );
    } else {
      _.mapKeys(
        _.groupBy(
          this.itemsLoaded as Api.Qualification[],
          "qualificationtype.des"
        ),
        (value, key) => {
          this.items.push({ header: key, isHeader: true });
          _.forEach(value, (item) => this.items.push(item));
        }
      );
    }
  }

  openEdit() {
    if (this.hasAvatar) {
      this.$emit("editAvatar");
    } else if (this.handleClick) {
      this.$emit("click");
    } else if (!this.hasRadioButtons) {
      const locationName = this.paymentType
        ? "profilePaymentEdit"
        : "profileEdit";
      let location: RawLocation = {
        name: locationName,
        params: { labelKey: this.labelKey },
      };
      if (this.multiline) {
        location = {
          ...location,
          query: { ...location.query, multiline: "true" },
        };
      }
      if (this.custom) {
        location = {
          ...location,
          query: { ...location.query, custom: "true" },
        };
      }
      this.$router.push(location);
    }
  }

  getLabel() {
    return this.$t(this.labelKey + ".label");
  }

  hasValue() {
    return (
      !_.isEmpty(this.value) && !_.isUndefined(this.value) && this.value != " "
    );
  }

  getValue() {
    if (this.hasValue()) {
      return this.value;
    } else if (this.showEmpty) {
      if (this.emptyText) {
        return this.emptyText;
      } else {
        return this.$t("emptyValue");
      }
    } else {
      return this.$t(this.labelKey + ".addValue");
    }
  }

  getValueClass() {
    const classes = {
      "no-value":
        _.isEmpty(this.value) || _.isUndefined(this.value) || this.value == " ",
    };
    if (this.valueClass) {
      classes[this.valueClass] = true;
    }
    return classes;
  }

  getValueCols() {
    if (this.hasSecondLabel) {
      return "5";
    } else {
      return "8";
    }
  }

  getSecondLabelCols() {
    return "4";
  }

  getDescription() {
    return this.$t(this.descriptionKey);
  }

  handleRadioButtonChange($event) {
    this.$emit("radioChanged", $event);
  }
}
