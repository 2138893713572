
import PersonalData from "@/components/PersonalData.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    PersonalData,
  },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
})
export default class ProfileView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action getUser;
  @Mutation
  setFillHeightOnMainContainer;

  async mounted() {
    this.setFillHeightOnMainContainer(false);
    await this.getUser();
  }
}
