
import Component from "vue-class-component";
import Vue from "vue";
import gql from "graphql-tag";
import { Mutation } from "vuex-class";
import _ from "lodash";

@Component({})
export default class PrivacyModalContent extends Vue {
  @Mutation showError;
  privacyContent = "";

  async mounted() {
    await this.getPrivacy();
  }

  async getPrivacy() {
    const termsRes = await this.$apollo.query({
      query: gql`
        query($release: String) {
          Privacy(release: $release) {
            guid
            accepted
            title
            content
          }
        }
      `,
      variables: {
        release: "1",
      },
      fetchPolicy: "no-cache",
    });
    if (_.isEmpty(termsRes.data.Privacy)) {
      this.showError("Impossibile recuperare la Privacy Policy");
    } else {
      this.privacyContent = termsRes.data.Privacy[0].content;
    }
  }
}
